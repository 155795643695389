import React from 'react';

export default function Solution({solution}) {
  return (
    <div className="solution">
      <div className="wrapper">
        <h2 className="ingo-title solution-title">Solution</h2>
        <div className="solution-text">
          Development of the application with integration of the existing backend, which was developed on the client
          side. This required communication and synchronization between the two teams. This task was successfully
          completed.
        </div>
        <div className="solution-container">
          <div className="solution-content">
            <div className="solution-content__text">
              The project consisted of four phases, as follows:
            </div>
            <ul className="solution-list">
              {
                solution.stages.map(stage => (
                  <li className="solution-list__item" key={stage.id}>
                    <div className="solution-list__item-num">{stage.id}</div>
                    <div className="solution-list__item-text">{stage.text}</div>
                  </li>
                ))
              }
            </ul>
            <div className="solution-content__text">
              Only patients of the Medical Center have full access to the functions of the application - registration
              requires a medical card in the Medical Center's database.
            </div>
          </div>
          <div className="solution-image">
            <img src={solution.image} alt=""/>
          </div>
        </div>
      </div>
    </div>
  );
}