import React from 'react';

import './MCIngo.scss';
import TopBanner from "./TopBanner";
import Overview from "./Overview";
import Problems from "./Problems";
import Challenge from "./Challenge";
import Solution from "./Solution";
import Result from "./Result";

export default function McIngo ({data}) {
  return (
    <div className="ingo-container">
      <TopBanner image={data.top_banner.image} />
      <Overview />
      <Problems problems={data.problems} />
      <Challenge challenge={data.challenge} />
      <Solution solution={data.solution} />
      <Result result={data.result} />
    </div>
  );
};
