import React from 'react';

export default function Overview () {

  return (
    <div className="overview">
      <div className="wrapper">
        <div className="overview-title">Project overview</div>
        <div className="overview-container">
          <h2 className="ingo-title overview-subtitle">Client</h2>
          <div className="overview-content">
            <div className="overview-text">
              A modern medical center in the Ukrainian capital (
              <a href="https://mci.ua/index.php" className="overview-text__link" target="_blank" rel="noreferrer">read more</a>
              ) that strives to provide a healthy future for its clients, helping them find the balance between success and good health.
            </div>
            <div className="overview-customer-title">
              Customer Challenge
            </div>
            <div className="overview-customer-text">
              To develop a mobile booking app to make the service even more convenient and easy for clinic users and visitors.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};