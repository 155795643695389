import React from 'react';
import SEO from "../../components/CommonComponents/SEO"
import Layout from "../../components/CommonComponents/Layout"
import Navigate from "../../components/SingleCase/Navigate"
import dataForMCIngo from "../../information/Cases/dataForMCIngo"
import { mainUrl } from "../../js/config"
import RelatedProjects from "../../components/SingleCase/RelatedProjects";
import MCIngo from "../../components/SingleCase/MCIngo";
import ContactUsExpertise from "../../components/CommonComponents/ContactUsExpertise";

const Ingo = () => {

  const {
    classComponent,
    nextLink,
    prevLink,
    relatedProjects,
    data,
  } = dataForMCIngo;

  const sendUrl = typeof window !== "undefined" ? window?.location?.href : "";

  return (
    <section className="singleProject">
      <SEO
        title="Medical center Application Case — OS-System"
        description="Working application for making an appointment with the doctor for the medical center located in the capital of Ukraine."
        canonical={`${mainUrl}/cases/ingo/`}
      />
      <Layout>
        <MCIngo data={data} />
        <Navigate
          classComponent={classComponent}
          nextLink={nextLink}
          prevLink={prevLink}
        />
        <RelatedProjects relatedProjects={relatedProjects} />
        <div id="anchor-form">
          <ContactUsExpertise form={0} url={sendUrl} />
        </div>
      </Layout>
    </section>
  );
};

export default Ingo;