import React from 'react';
import { Link } from 'react-scroll'

export default function Problems({problems}) {
  return (
    <div className="problems">
      <div className="wrapper">
        <h2 className="ingo-title problems-title">
          Problems and their causes
        </h2>
        <div className="problems-text">
          Developing a medical app can increase customer loyalty, save time, and improve communication <br/> between patients
          and doctors by
          <span className="problems-text-orange"> solving the following problems:</span>
        </div>
        <div className="problems-desktop-content">
          <img src={problems.image} alt=""/>
        </div>
        <ul className="problems-mobile-content">
          {
            problems.content.map(item => (
              <li className={`problems-mobile-content__item item-${item.id}`} key={item.id}>
                {item.text}
              </li>
            ))
          }
        </ul>
        <div className="problems-contact">
          <div className="problems-contact__text">
            Are you familiar with these problems? Tell us more about your problem and <br/> our specialists will contact you
          </div>
          <div className="problems-contact__btn">
            <Link
              className=""
              to="anchor-form"
              spy={true}
              smooth={'easeOutSine'}
              offset={10}
              duration={1000}
            >
              Tell us about your problem
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};