import React from 'react';

export default function Result({result}) {
  return (
    <div className="result" style={{backgroundImage:`url(${result.background})`}}>
      <div className="wrapper">
        <div className="result-header">
          <div className="result-header__image">
            <img src={result.image} alt=""/>
          </div>
          <div className="result-header__text">
            We managed to bring the clinic to a new level of digitalization of the business, which will increase the
            number of clients through improved service and also increase the competitiveness of the business.
          </div>
        </div>
        <h2 className="ingo-title result-title">Value delivered</h2>
        <div className="result-text">
          The feature that we were able to implement that provides the most benefit to our client is the ability for
          users to book appointments with the app, reducing the number of operators needed and relieving the load on the
          phone line.
        </div>
        <h3 className="result-subtitle">
          With this app, users can:
        </h3>
        <ul className="result-list">
          {
            result.list.map(item => (
              <li className="result-list__item" key={item.id}>
                <span></span>
                {item.text}
              </li>
            ))
          }
        </ul>
        <div className="result-stores">
          In the stores, you can learn more about the apps for
          <a href="https://play.google.com/store/apps/details?id=com.mcingo.mcingo" target="_blank" rel="noreferrer">
            {' '}Android
          </a> and
          <a href="https://apps.apple.com/ua/app/mc-ingo/id1592321213?l=ru" target="_blank" rel="noreferrer">
            {' '}iOS
          </a> created by our team
        </div>
      </div>
    </div>
  );
}
