import { People, Phone, TwoPhone, ThreePhone, Illustration, BgResult } from "../../images/SingleCase/MCIngo";

const dataForMCIngo = {
  type: "project",
  smart: true,
  classComponent: "MCIngo",
  nextLink: "cases/utility-management-software/",
  prevLink: "cases/pickup-sport/",
  data: {
    top_banner: {
      image: Phone,
    },
    overview: {},
    problems: {
      image: Illustration,
      content: [
        {id: '1', text: 'Lack of customer awareness of clinic services'},
        {id: '2', text: 'Making an appointment with a doctor takes time'},
        {id: '3', text: 'Access to the client\'s medical history for both doctor and patient is not fast and easy enough'},
        {id: '4', text: 'More staff are needed to handle calls'},
      ]
    },
    challenge: {
      image: TwoPhone,
      description: [
        {
          id: 1,
          title: 'Platform',
          list: 'iOS, Android',
          icon: 'fa-th-large',
        },
        {
          id: 2,
          title: 'Technology',
          list: 'Flutter, Dart, MobX, Flutter libs (Connectivity, Google Maps, Dio), Microsoft Teams',
          icon: 'fa-cog',
        },
        {
          id: 3,
          title: 'Team',
          list: '1 Project Manager\n' + '2 Front Developers\n' + '1 QA Engineer',
          icon: 'fa-users',
        },
        {
          id: 4,
          title: 'Duration',
          list: '6 months',
          icon: 'fa-calendar',
        },
        {
          id: 5,
          title: 'Client',
          list: 'Medical Clinical Center Enterprise',
          icon: 'fa-user',
        },
      ]
    },
    solution: {
      image: ThreePhone,
      stages: [
        {id: 1, text: 'Identification of needs'},
        {id: 2, text: 'Design study'},
        {id: 3, text: 'Development'},
        {id: 4, text: 'Release'},
      ]
    },
    result: {
      image: People,
      background: BgResult,
      list: [
        {id: 1, text: 'View doctors\' work schedules and make their own doctor\'s appointments'},
        {id: 2, text: 'Access personal medical documents, doctor\'s reports, test results, X-rays, and ultrasound scans at any time'},
        {id: 3, text: 'In the course of our work, we managed to create a convenient interface that allows you to see your visits to the doctor and the results of these visits'},
        {id: 4, text: 'The ability to attach patient documents has been implemented'},
        {id: 5, text: 'A full digitalization of the process of a doctor\'s appointment, taking into account the doctor\'s free time, was implemented, also added was the ability to search for the name of the doctor or their specialty'},
        {id: 6, text: 'Implemented the authorization of users by phone number via SMS code'},
        {id: 7, text: 'Implemented the ability to conduct online medical consultations via video communication from the application'},
      ]
    },
  },
  relatedProjects: [
    {
      link: "/cases/karpatska-chaika",
      id: "seagull",
      title: "Carpathian Seagull",
    },
    {
      link: "/cases/crm-sap-integration",
      id: "crmSap",
      title: "CRM/SAP Integration",
    },
    {
      link: "/cases/gud-job",
      id: "gud-job",
      title: "Gud Job",
    },
    {
      link: "/cases/ilich-realty",
      id: "illich3",
      title: "Ilich Realty",
    },
  ],
}

export default dataForMCIngo;