import React from 'react';

export default function TopBanner ({image}) {

  return (
    <div className="top-banner">
      <div className="wrapper">
        <div className="top-banner-container">
          <div className="top-banner-content">
            <h1 className="top-banner-title">
              Mobile application <br/> for a medical center
            </h1>
            <div className="top-banner-text">
              Working application for making an appointment with the doctor for the medical center located in the capital of Ukraine.
            </div>
          </div>
          <div className="top-banner-image">
            <img src={image} alt=""/>
          </div>
        </div>
      </div>
    </div>
  );
};
