import React from 'react';

export default function Challenge({challenge}) {
  return (
    <div className="challenge">
      <div className="wrapper">
        <h2 className="ingo-title challenge-title">Business Challenge</h2>
        <div className="challenge-container">
          <div className="challenge-content">
            <div className="challenge-content__text">
              The team was given the task of developing from scratch a mobile app that would allow customers to
              make appointments with the doctors of the clinic and view related documentation.
            </div>
            <div className="challenge-content__text">
              The team's mission was to develop an app through which users could:
            </div>
            <ol className="challenge-content__list">
              <li>1. View the schedule of the doctors and make appointments themselves.</li>
              <li>2. Access personal medical documents, doctor's reports, test results, X-rays, and ultrasound scans at any time.
              </li>
              <li>3. We wanted customers to be able to log in securely: For authorization, you must specify a phone number
                and confirm the entry with the user verification code received via SMS.
              </li>
            </ol>
          </div>
          <ul className="challenge-description">
            {
              challenge.description.map(item => (
                <li className="challenge-description__item" key={item.id}>
                  <i className={`fa ${item.icon} challenge-description__icon`}></i>
                  <div className="challenge-description__title">{item.title}</div>
                  <div className="challenge-description__list">{item.list}</div>
                </li>
              ))
            }
          </ul>
        </div>
        <div className="challenge-image">
          <img src={challenge.image} alt=""/>
        </div>
      </div>
    </div>
  );
}
